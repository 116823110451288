import React from 'react';
import './styles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StorySlider from './Slider';

const SuccessStories = () => {
    return (
        <div className='success-stories-wrap'>
            <div className='section-header'>
                <h2>Success Stories</h2>
                <p>
                    We've changed the way hundreds of teams work today with the products we are building in the HR
                    ecosystem.
                </p>
            </div>

            <div className='success-stories'>
                <div className='stories-right'>
                    <div className='stories-section'>
                        <div className='stories-section'>
                            <StorySlider />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessStories;
