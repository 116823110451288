import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './styles.scss';

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 1000,
        };
        return (
            <div className='sw-container'>
                <h2 className='partnerHeader'>Our Clients</h2>
                <div className='sw-partners'>
                    <style>{`${styles}`}</style>
                    <Slider {...settings}>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-01.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-01.svg'}
                                    alt={'Springworks Clients - Burst'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-02.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-02.svg'}
                                    alt={'Springworks Clients'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-03.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-03.svg'}
                                    alt={'Springworks Clients - TORO'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/springworks/partners/springworks-clients-godrej.svg'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/springworks/partners/springworks-clients-godrej.svg'
                                    }
                                    alt={'Springworks Clients - Godrej'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-05.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-05.svg'}
                                    alt={'Springworks Clients - Instamojo'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-06.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-06.svg'}
                                    alt={'Simpl'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-07.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-07.svg'}
                                    alt={'Springworks Clients - Cred'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-08.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-08.svg'}
                                    alt={'Apollo Hospitals'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-09.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-09.svg'}
                                    alt={'Meesho'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                        <div className='slider-card'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/springworks/partners/partner-10.svg'}
                                    data-src={'https://sw-assets.gumlet.io/springworks/partners/partner-10.svg'}
                                    alt={'Our Clients'}
                                    loading='lazy'
                                />
                            </span>
                        </div>
                    </Slider>
                </div>
            </div>
        );
    }
}
