import React from 'react';
import Navbar from '../components/springworks/Navbar';
import Headers from '../components/springworks/Header';
import HeaderContainer from '../components/springworks/HeaderContainer';
import CounterValues from '../components/springworks/CounterValues';
import OurClients from '../components/springworks/OurClients';
import RecruitingPipeline from '../components/springworks/RecruitingPipeline';
import SuccessStories from '../components/springworks/SuccessStories';
import ContactUs from '../components/springworks/ContactUs';
import Footer from '../components/springworks/Footer';
import { Helmet } from 'react-helmet';
import '../components/springworks/styles.scss';

class Trivia extends React.Component {
    render() {
        return (
            <div className='Home text-center'>
                <Helmet>
                    <title>HR Software Solutions for Growing Businesses | Springworks</title>
                    <meta
                        data-react-helmet='true'
                        name='description'
                        content='Springworks builds human resources software solutions to solve challenges in recruitment, background verification & employee engagement with Blockchain and AI.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='HR Software Solutions for Growing Businesses | Springworks' />
                    <meta
                        property='og:description'
                        content='Springworks builds human resources software solutions to solve challenges in recruitment, background verification & employee engagement with Blockchain and AI.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='https://assets-springworks.s3.amazonaws.com/Springworks.jpg' />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/Springworks.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta
                        property='twitter:title'
                        content='HR Software Solutions for Growing Businesses | Springworks'
                    />
                    <meta
                        property='twitter:description'
                        content='Springworks builds human resources software solutions to solve challenges in recruitment, background verification & employee engagement with Blockchain and AI.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/Springworks.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/' />
                </Helmet>

                <HeaderContainer>
                    <Navbar />
                    <Headers />
                </HeaderContainer>
                <CounterValues />
                <RecruitingPipeline />
                <SuccessStories />
                <OurClients />
                <ContactUs />
                <Footer />
            </div>
        );
    }
}

export default Trivia;
