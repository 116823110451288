import React from 'react';
import './styles.scss';
import Carousel from 'react-bootstrap/Carousel';

const RecruitingPipeline = () => {
    return (
        <div className='sw-container' id='viewProducts'>
            <div className='recruit-pipeline' id='ourProducts'>
                <div className='section-header'>
                    <h2>The HR Landscape</h2>
                    <p>
                        We make recruitment, onboarding and employee engagement seamless and intelligent with empathy.
                    </p>
                </div>

                <div className='recruit-flow flex-div'>
                    <div className='recruit-state flex-div'>
                        <img
                            src={'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/recruitment.svg'}
                            data-src={'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/recruitment.svg'}
                            alt='Seamless Recruitment'
                            loading='lazy'
                        />
                        <span>Recruitment</span>
                    </div>

                    <div className='recruit-state-divider' />

                    <div className='recruit-state flex-div'>
                        <img
                            src={'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/engagement.svg'}
                            data-src={'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/engagement.svg'}
                            alt='New Employee Onboarding Process'
                            loading='lazy'
                        />
                        <span>Onboarding</span>
                    </div>
                    <div className='recruit-state-divider' />

                    <div className='recruit-state flex-div'>
                        <img
                            src={'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/onboarding.svg'}
                            data-src={'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/onboarding.svg'}
                            alt='Employee Engagement with Springworks'
                            loading='lazy'
                        />
                        <span>Engagement</span>
                    </div>
                </div>

                <div className='recruit-description-wrap'>
                    <div className='recruit-sub-header recruit-sub-header-mob'>
                        <h3>Recruitment</h3>
                    </div>

                    <div className='recruit-description recruit-description-left'>
                        <div className='re-desc-illustration-bg rerecruit-bg flex-div '>
                            <div className='re-desc-illustration'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/hire-right-candidate-with-springrecruit.svg'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/hire-right-candidate-with-springrecruit.svg'
                                    }
                                    alt='Hire Right Candidate with SpringRecruit'
                                    loading='lazy'
                                />
                            </div>

                            <div className='re-desc-illustration-animation flex-div'>
                                <div className='re-desc-illustration-emitter'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/middle-icon.svg'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/middle-icon.svg'
                                        }
                                        alt='Hire Right Candidate with SpringRecruit'
                                        loading='lazy'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='recruit-description'>
                        <div className='recruit-sub-header'>
                            <h3>Recruitment</h3>
                            <p>
                                Bring intelligence and efficiency to recruiting. Spend less time on paperwork and
                                processing and more on doing what matters the most - hiring the right candidates,
                                quicker.
                            </p>
                        </div>

                        <div className='sw-products-wrap flex-div'>
                            <div className='sw-product'>
                                <div className='sw-product-logo'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/a-decentralized-verified-professional-profile-platform.svg'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/a-decentralized-verified-professional-profile-platform.svg'
                                        }
                                        alt='A decentralized verified professional profile platform'
                                        loading='lazy'
                                    />
                                </div>
                                <p>A decentralized, verified professional profile platform</p>
                                <a href='https://springrole.com/' target='_blank' rel='noreferrer noopener'>
                                    Learn More
                                </a>
                            </div>

                            <div className='sw-product'>
                                <div className='sw-product-logo'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/top-free-ats-springrecruit.svg'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/top-free-ats-springrecruit.svg'
                                        }
                                        alt='Top Free ATS - SpringRecruit'
                                        loading='lazy'
                                    />
                                </div>
                                <p>An Applicant Tracking System that’s free FOREVER</p>
                                <a href='https://www.springrecruit.com/' target='_blank' rel='noreferrer noopener'>
                                    Learn More
                                </a>
                            </div>
                        </div>
                        <div className='sw-products-wrap flex-div'>
                            <div className='sw-product'>
                                <div className='sw-product-logo'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springhire.svg'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springhire.svg'
                                        }
                                        alt='A Ffull-stack technology hiring and staffing agency'
                                        loading='lazy'
                                    />
                                </div>
                                <p>A full-stack technology hiring and staffing agency</p>
                                <a href='https://www.springhire.info/' target='_blank' rel='noreferrer noopener'>
                                    Learn More
                                </a>
                            </div>
                            <div className='sw-product'>
                                <div className='sw-product-logo'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springparse.svg'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springparse.svg'
                                        }
                                        alt='Manage resumes smarter, faster and at scale'
                                        loading='lazy'
                                    />
                                </div>
                                <p>Manage resumes smarter, faster and at scale.</p>
                                <a href='mailto:info@springworks.in'>Request for Demo</a>
                            </div>
                        </div>

                        <div className='who-are-we-values-wrap-slider'>
                            <Carousel>
                                <Carousel.Item>
                                    <div className='sw-product'>
                                        <div className='sw-product-logo'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/a-decentralized-verified-professional-profile-platform.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/a-decentralized-verified-professional-profile-platform.svg'
                                                }
                                                alt='A decentralized verified professional profile platform'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p>A decentralized, verified professional profile platform</p>
                                        <a href='https://springrole.com/' target='_blank' rel='noreferrer noopener'>
                                            Learn More
                                        </a>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='sw-product'>
                                        <div className='sw-product-logo'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/top-free-ats-springrecruit.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/top-free-ats-springrecruit.svg'
                                                }
                                                alt='SpringRecruit'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p>An Applicant Tracking System that’s free FOREVER</p>
                                        <a
                                            href='https://www.springrecruit.com/'
                                            target='_blank'
                                            rel='noreferrer noopener'
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='sw-product'>
                                        <div className='sw-product-logo'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springhire.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springhire.svg'
                                                }
                                                alt='A full-stack technology hiring and staffing agency'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p>A full-stack technology hiring and staffing agency</p>
                                        <a
                                            href='https://www.springhire.info/'
                                            target='_blank'
                                            rel='noreferrer noopener'
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='sw-product'>
                                        <div className='sw-product-logo'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springparse.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/springparse.svg'
                                                }
                                                alt='Manage resumes smarter, faster and at scale'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p>Manage resumes smarter, faster and at scale.</p>
                                        <div
                                            className='request-demo'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {}}
                                            onKeyDown={() => {}}
                                            tabIndex={'0'}
                                            role={'link'}
                                        >
                                            Request for Demo
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>

                <div className='recruit-description-wrap'>
                    <div className='recruit-sub-header recruit-sub-header-mob'>
                        <h3>Onboarding</h3>

                        <div className='re-desc-illustration'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/onboarding-ill.svg'}
                                data-src={
                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/onboarding-ill.svg'
                                }
                                alt='Background Verification and Employee Onboarding'
                                loading='lazy'
                            />
                        </div>
                    </div>
                    <div className='recruit-description recruit-description-left'>
                        <div className='recruit-sub-header onboarding-header'>
                            <h3>Onboarding</h3>
                            <p>
                                Find the right talent faster with background verifications that are seamless, flexible
                                and incredibly reliable, all powered by the blockchain.
                            </p>
                        </div>

                        <div className='sw-products-wrap sw-products-wrap-onboarding'>
                            <div className='sw-product'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/background-verification-and-kyc-solutions.svg'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/background-verification-and-kyc-solutions.svg'
                                    }
                                    alt='Background verification and KYC solutions'
                                    loading='lazy'
                                />
                                <p>Background verification and KYC solutions.</p>
                                <a href='https://springverify.com/' target='_blank' rel='noreferrer noopener'>
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='recruit-description onboarding-desk'>
                        <div className='re-desc-illustration-bg onboard-bg flex-div'>
                            <div className='re-desc-illustration'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/onboarding-ill.svg'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/onboarding-ill.svg'
                                    }
                                    alt='Background Verification and Employee Onboarding'
                                    loading='lazy'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='recruit-description-wrap'>
                    <div className='recruit-sub-header recruit-sub-header-mob'>
                        <h3>Employee Engagement</h3>
                    </div>
                    <div className='recruit-description'>
                        <div className='re-desc-illustration-bg employeeEngagement flex-div'>
                            <div className='re-desc-illustration'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/employeeEngagement.svg'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/employeeEngagement.svg'
                                    }
                                    alt='Employee Engagement Activities with Trivia'
                                    loading='lazy'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='recruit-description'>
                        <div className='recruit-sub-header employee-header'>
                            <h3>Employee Engagement</h3>
                            <p>
                                Build a culture everyone wants to be a part of. Inspire your team to reach new heights,
                                have a laugh at work and engage deeper.
                            </p>
                        </div>
                        <div className='sw-products-wrap flex-div'>
                            <div className='sw-product sw-product-trivia'>
                                <div className='sw-product-logo'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/trivia-logo.svg'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/trivia-logo.svg'
                                        }
                                        alt='Interactive games for remote team-building'
                                        loading='lazy'
                                    />
                                </div>
                                <p>Interactive games for remote team-building.</p>
                                <a href={'/trivia/'} target='_blank' rel='noreferrer noopener'>
                                    Learn More
                                </a>
                            </div>

                            <div className='sw-product'>
                                <div className='sw-product-logo'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/engagewith.svg'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/engagewith.svg'
                                        }
                                        alt='EngageWith - Employee Rewards and Recognition Platform'
                                        loading='lazy'
                                    />
                                </div>
                                <p>Employee Rewards and Recognition platform</p>
                                <a href={'/engagewith/'} target='_blank' rel='noreferrer noopener'>
                                    Learn More
                                </a>
                            </div>
                        </div>

                        <div className='who-are-we-values-wrap-slider'>
                            <Carousel>
                                <Carousel.Item>
                                    <div className='sw-product sw-product-trivia'>
                                        <div className='sw-product-logo'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/trivia-logo.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/trivia-logo.svg'
                                                }
                                                alt='Interactive games for remote team-building'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p>
                                            Interactive games for team-building right inside Slack and Microsoft Teams.
                                        </p>
                                        <a href={'/trivia/'} target='_blank' rel='noreferrer noopener'>
                                            Learn More
                                        </a>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='sw-product'>
                                        <div className='sw-product-logo'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/engagewith.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/springworks/recruiting-pipeline/engagewith.svg'
                                                }
                                                alt='EngageWith - Employee Rewards and Recognition Platform'
                                                placeholder={'tracedSVG'}
                                                loading='lazy'
                                            />
                                        </div>
                                        <p>Employee Rewards and Recognition platform</p>
                                        <a href={'/engagewith/'} target='_blank' rel='noreferrer noopener'>
                                            Learn More
                                        </a>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecruitingPipeline;
