import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './styles.scss';

class StorySlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };
        return (
            <div className='container'>
                <link rel='stylesheet' type='text/css' charSet='UTF-8' href='slick.min.css' />
                <link rel='stylesheet' type='text/css' href={'./slick-theme.min.css'} />
                <style>{`${styles}`}</style>
                <Slider {...settings} className='stories-wrap'>
                    <div className='slider-item'>
                        <div className='stories-section-codes'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                alt='open quote'
                                loading='lazy'
                            />
                        </div>
                        <div className='stories-para'>
                            <p>
                                “My team demanded more! They have had a great time with Trivia on Slack. With a wide
                                range of topics, it's almost impossible to cheat and it sparks conversation between
                                groups that don't traditionally interact. Trivia buffs have found each other, and
                                general puzzle people have popped up in surprising specialty areas.”
                            </p>
                        </div>
                        <div className='stories-profile'>
                            <div className='stories-profile-pic'>
                                <img
                                    src={'https://sw-assets.gumlet.io/photos/christina.jpg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/photos/christina.jpg'}
                                    alt='christina Kinney'
                                    loading='lazy'
                                />
                            </div>

                            <div className='stories-profile-desg'>
                                <h4>Christina Kinney</h4>
                                <p>Chief Operating Officer, Reach</p>
                            </div>
                        </div>
                    </div>
                    <div className='slider-item'>
                        <div className='stories-section-codes'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                alt='open quote'
                                loading='lazy'
                            />
                        </div>
                        <div className='stories-para'>
                            <p>
                                “Trivia has brought something fun to my workday. Trivia takes the stress/tension of work
                                away and gives a refreshing time. It's like taking a break without actually taking a
                                break.{' '}
                                <span role={'img'} aria-label={'smiley'}>
                                    😛
                                </span>{' '}
                                Along with a fun experience, it improves knowledge as well which is the best thing about
                                it.{' '}
                                <span role={'img'} aria-label={'smiley'}>
                                    😛
                                </span>
                                ”
                            </p>
                        </div>
                        <div className='stories-profile'>
                            <div className='stories-profile-pic'>
                                <img
                                    src={'https://sw-assets.gumlet.io/photos/shagun.jpeg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/photos/shagun.jpeg'}
                                    alt='Shagun Chaudhary'
                                    loading='lazy'
                                />
                            </div>

                            <div className='stories-profile-desg'>
                                <h4>Shagun Chaudhary</h4>
                                <p>Community Growth, KUKU FM</p>
                            </div>
                        </div>
                    </div>

                    <div className='slider-item'>
                        <div className='stories-section-codes'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                alt='open quote'
                                loading='lazy'
                            />
                        </div>

                        <div className='stories-para'>
                            <p>
                                “Springworks has built SpringVerify on WhatsApp so that it’s easy to understand. It’s
                                quick, seamless and we can identify lots of use cases for this product.”
                            </p>
                        </div>

                        <div className='stories-profile'>
                            <div className='stories-profile-pic'>
                                <img
                                    src={'https://sw-assets.gumlet.io/photos/vivek-madani.jpeg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/photos/vivek-madani.jpeg'}
                                    alt='Manju Shiv'
                                    loading='lazy'
                                />
                            </div>

                            <div className='stories-profile-desg'>
                                <h4>Vivek Madani</h4>
                                <p>Community Growth, KUKU FM</p>
                            </div>
                        </div>
                    </div>

                    <div className='slider-item'>
                        <div className='stories-section-codes'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                alt='open quote'
                                loading='lazy'
                            />
                        </div>
                        <div className='stories-para'>
                            <p>
                                “It’s been terrific to see how Trivia has brought our team together every now and then.
                                The quizzes are a treat when you’ve got a pile of work and you need to pump those
                                creative juices. Our team just loves the new game types - opinion polls and gotcha!”
                            </p>
                        </div>
                        <div className='stories-profile'>
                            <div className='stories-profile-pic'>
                                <img
                                    src={'https://sw-assets.gumlet.io/photos/narman.jpg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/photos/narman.jpg'}
                                    alt='Varun Dhamija'
                                    loading='lazy'
                                />
                            </div>

                            <div className='stories-profile-desg'>
                                <h4>Naman Sarawagi</h4>
                                <p>Co-Founder, Refrens.com</p>
                            </div>
                        </div>
                    </div>

                    <div className='slider-item'>
                        <div className='stories-section-codes'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                alt='open quote'
                                loading='lazy'
                            />
                        </div>
                        <div className='stories-para'>
                            <p>
                                “This is an amazing addition to our Slack workspace. We were doing weekend quizzes in
                                smaller groups outside of work during the lockdown. But this brings together our entire
                                team, including interns and freelancers for some fun together. Love the new feature
                                updates - especially choosing difficulty levels, leaderboards, image-based quizzes,
                                etc.”
                            </p>
                        </div>
                        <div className='stories-profile'>
                            <div className='stories-profile-pic'>
                                <img
                                    src={'https://sw-assets.gumlet.io/photos/anurag.jpg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/photos/anurag.jpg'}
                                    alt='Anurag Dwivedi'
                                    loading='lazy'
                                />
                            </div>

                            <div className='stories-profile-desg'>
                                <h4>Anurag Dwivedi</h4>
                                <p>Co-Founder, Rocketium</p>
                            </div>
                        </div>
                    </div>

                    <div className='slider-item'>
                        <div className='stories-section-codes'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/left-code.svg'}
                                alt='open quote'
                                placeholder={'tracedSVG'}
                                loading='lazy'
                            />
                        </div>
                        <div className='stories-para'>
                            <p>
                                “SpringVerify on WhatsApp is pretty easy. Fairly accurate and I think it’s a new
                                experience altogether. Traditionally, such verifications was cumbersome but this product
                                from Springworks is like a breath of fresh air.”
                            </p>
                        </div>
                        <div className='stories-profile'>
                            <div className='stories-profile-pic'>
                                <img
                                    src={'https://sw-assets.gumlet.io/photos/varun-dhamija.jpg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/photos/varun-dhamija.jpg'}
                                    alt='varun-dhamija'
                                    loading='lazy'
                                />
                            </div>

                            <div className='stories-profile-desg'>
                                <h4>Varun Dhamija</h4>
                                <p>Vice President and Business Head, Pearson Education</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}

export default StorySlider;
