import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './styles.scss';

const CounterValues = () => {
    return (
        <div className='sw-container'>
            <div className='who-are-we-values-wrap-desk'>
                <div className='who-are-we-values-wrap'>
                    <div className='who-are-we-values-inner'>
                        <div className='value-img'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/stats/57320-players-on-trivia.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/stats/57320-players-on-trivia.svg'}
                                alt='trivia players'
                            />
                        </div>
                        <div className='who-are-we-values'>
                            <h5>500,000</h5>
                            <p>Players on Trivia</p>
                        </div>
                    </div>

                    <div className='who-are-we-values-inner'>
                        <div className='value-img'>
                            <img
                                src={
                                    'https://sw-assets.gumlet.io/springworks/stats/verifications-conducted-by-springverify-annually.svg'
                                }
                                data-src={
                                    'https://sw-assets.gumlet.io/springworks/stats/verifications-conducted-by-springverify-annually.svg'
                                }
                                alt='annual verifications'
                            />
                        </div>
                        <div className='who-are-we-values'>
                            <h5>100,000+</h5>
                            <p>Verifications conducted by SpringVerify annually</p>
                        </div>
                    </div>

                    <div className='who-are-we-values-inner'>
                        <div className='value-img'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/stats/2000-clients-with-springworks.svg'}
                                data-src={
                                    'https://sw-assets.gumlet.io/springworks/stats/2000-clients-with-springworks.svg'
                                }
                                alt='springworks clients'
                            />
                        </div>

                        <div className='who-are-we-values'>
                            <h5>2,000+</h5>
                            <p>Clients</p>
                        </div>
                    </div>

                    <div className='who-are-we-values-inner'>
                        <div className='value-img'>
                            <img
                                src={
                                    'https://sw-assets.gumlet.io/springworks/stats/70000-profiles-on-springrole-and-springrecruit.svg'
                                }
                                data-src={
                                    'https://sw-assets.gumlet.io/springworks/stats/70000-profiles-on-springrole-and-springrecruit.svg'
                                }
                                alt='profiles on SpringRecruit'
                            />
                        </div>
                        <div className='who-are-we-values'>
                            <h5>170,000+</h5>
                            <p>Profiles on SpringRole and SpringRecruit</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='who-are-we-values-wrap-slider'>
                <Carousel>
                    <Carousel.Item>
                        <div className='who-are-we-values'>
                            <h5>31,537</h5>
                            <p>Players on Trivia</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='who-are-we-values'>
                            <h5>6,000+</h5>
                            <p>Verifications conducted by SpringVerify</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='who-are-we-values'>
                            <h5>2,000+</h5>
                            <p>Clients</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='who-are-we-values'>
                            <h5>70,000+</h5>
                            <p>Professional Profiles on SpringRole and SpringRecruit</p>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
};

export default CounterValues;
