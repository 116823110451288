import React from 'react';
import './styles.scss';

const HeaderContainer = (props) => {
    return (
        <div className='sw-header-container'>
            <div className='sw-container'>{props.children}</div>
        </div>
    );
};

export default HeaderContainer;
