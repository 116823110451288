import React from 'react';
import './styles.scss';

const ContactUs = () => {
    return (
        <div className='contact-us-wrap' id='contactUs'>
            <div className='section-header'>
                <h2>We're here for you</h2>
                <p>
                    If you want to book a live demo for a product, get instant support or talk to our team to make your
                    work easier, let's chat.
                </p>
                <a href='mailto:support@springworks.in'>CONTACT US</a>
            </div>
        </div>
    );
};

export default ContactUs;
