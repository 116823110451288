import React from 'react';
import './style.scss';
import Fade from 'react-reveal/Fade';

const Header = () => {
    //Added div around h1 as it was causing the word to break up in between.
    return (
        <div className='header-content'>
            <Fade top cascade>
                <div>
                    <h1>Redefining the future of HR today</h1>
                </div>
                <div>
                    <h2>Revolutionizing HR with Blockchain, Machine Learning and Empathy</h2>
                </div>
                <a href={'#viewProducts'}>VIEW PRODUCTS</a>
            </Fade>
        </div>
    );
};

export default Header;
